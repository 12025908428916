import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import axios from "axios";
import {
    APPNAME,
    attachTokenToHeaders,
    Capitalize,
    CLI,
    compare_date,
    format_date,
    SERVER_URL
} from "../../../../helpers/utils";
import {toast} from "react-toastify";
import swal from "@sweetalert/with-react";
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import Paginate from "../../../../components/Paginate/Paginate";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const Shops = ({t}) => {

    document.title = APPNAME+" :: "+t('Shops')

    const [currentShop,setCurrentShop] = useState([]);
    const [shops,setShops] = useState([])

    useEffect(() => {
        getAllShop()
    },[])

    useEffect(() => {
        setCurrentShop(shops?.slice(indexOfFirstPost, indexOfLastPost))
    },[shops])


    const countryOptions = [
        { value: 'benin' , label: "Benin"},
        { value: "cote d'ivoire" , label: "Cote d'ivoire"},
        { value: 'mali' , label: "Mali"},
        { value: 'niger' , label: "Niger"},
        { value: 'senegal' , label: "Sénégal"},
        { value: 'togo' , label: "Togo"},
    ]

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const animatedComponents = makeAnimated();
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;



    useEffect(() => {
        setCurrentShop(shops?.slice(indexOfFirstPost, indexOfLastPost))
    },[currentPage])


    const getAllShop = async () => {
        await axios.get(SERVER_URL+"admin/shops",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setShops(response.data.shops)
                setLoading(false)
            }else{
                toast.error(t(response.data.message))
            }
        })
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(shops?.length / postsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const SearchFilter = (event) =>{
        if (event == ""){
            setCurrentShop(shops?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = shops.filter(shop => {
                return shop.shopName.toLowerCase().includes(event.toLowerCase())
            })
            setCurrentShop(filter)
        }
    }


    const toggleStatus = async (shop_key,type) =>{

        await swal({
            title : t('ToggleStatus'),
            text : type === 2 ? t('SureToDisabledShop') : t('SureToEnabledShop'),
            buttons: [t("Cancel"), t('Yes')],
            dangerMode: type === 2,
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/toggle/shop/${type}/${shop_key}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllShop()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }

    const filterBy = (value,type) => {
        if (value == ""){
            setCurrentShop(shops?.slice(indexOfFirstPost, indexOfLastPost))
        }else{
            const  filter = shops.filter(shop => shop?.[type] == value)
            setCurrentShop(filter)
        }
    }

    const exportXlsX  = () => {
        if (shops.length > 0) {
            const headers = [
                t('Category'),
                t('Country'),
                t('City'),
                t('Address'),
                t('Shop'),
                t('Domaine'),
                t('Owner'),
                t('Phone'),
                t('Email'),
                t('SubscriptionEnd'),
                t('Status')
            ]
            const url = SERVER_URL+"admin/shop/export"
            const data = {
                headers : headers,
                local : localStorage.getItem('I18N_LANGUAGE')
            }

            axios
                .post(url,data, {responseType: 'blob'})
                .then(({ data }) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', t('Shops')+'-list.xlsx')
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                });
        }else{
            toast.error(t('EmptyList'))
        }
    }


    const relaunch = async (id) => {
        await swal({
            title : t('ToggleStatus'),
            text : t('SureToEnabledShop'),
            buttons: [t("Cancel"), t('Yes')],
            icon : "success"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/shop/relaunch/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllShop()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }


    const deleteModal = async (id) => {
        await swal({
            title : t('Delete'),
            text : t('SureToDeleteShop'),
            buttons: [t("Cancel"), t('Yes')],
            icon : "warning"
        }).then(async (val) => {
            if (val){
                try {
                    const response = await axios.get(`${SERVER_URL}admin/shop/delete/${id}`,attachTokenToHeaders())
                    if (response.data.succeed){
                        toast.success(t(response.data.message))
                        getAllShop()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Shops')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Shops')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card" id="tasksList">
                        <div className="card-body mb-30">
                            <div >
                                <div className="row ">
                                    <div className="col-lg-4">
                                        <div className="row">
                                            <div className="col-lg-4 shop-select-container">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    defaultValue={[]}
                                                    isDisabled={false}
                                                    onChange={event => filterBy(event.value, 'country')}
                                                    id="country"
                                                    className="front"
                                                    options={countryOptions}
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <button className="btn btn-success" onClick={event => exportXlsX()}><i
                                                    className="bx bx-download"></i> XLSX
                                                </button>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="offset-lg-4 col-lg-4">
                                        <div className="search-box">
                                            <input type="text" className="form-control search bg-light border-light"
                                                   placeholder={t('Search')}
                                                   onChange={event => SearchFilter(event.target.value)} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-card table-responsive-xl mb-4" style={{ backgroundColor: 'red' }}>
                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th className="sort">{t('Category')}</th>
                                        <th className="sort">{t('Country')}</th>
                                        {/*<th className="sort">{t('City')}</th>*/}
                                        <th className="sort">{t('Phone')}</th>
                                        <th className="sort">{t('Shop')}</th>
                                        <th className="sort">{t('Domaine')}</th>
                                        <th className="sort">{t('Owner')}</th>
                                        <th className="sort">{t('SubscriptionEnd')}</th>
                                        <th className="sort">{t('Step')}</th>
                                        <th className="sort">Status</th>
                                        <th className="sort" style={{ textAlign: 'center' }}>{t('Action')}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan="10">
                                                    <p className="text-center">
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/> {t('Loading')}...
                                                    </p>
                                                </td>
                                            </tr>

                                            :

                                            <>
                                                {
                                                    currentShop?.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                currentShop?.map((shop,key)=>
                                                                    <tr key={key}>
                                                                        <td>{shop?.[localStorage.getItem('I18N_LANGUAGE')]}</td>
                                                                        <td>{shop.country ? Capitalize(shop.country) : '-'}</td>
                                                                        {/*<td>{shop.city ? Capitalize(shop.city) : '-'}</td>*/}
                                                                        <td>{shop?.phone}</td>
                                                                        <td>{shop.shopName}
                                                                            {shop?.demoEmail ? <b className="badge badge-success">Demo</b> : ''}
                                                                            {shop?.trial ? <b className="badge badge-warning">{t('Trial')}</b> : ''}
                                                                        </td>
                                                                        <td>{'https://' + shop.domaine + '.' + CLI}</td>
                                                                        <td>{shop.firstname} {shop.lastname}</td>
                                                                        <td>{shop.current_period_end ?
                                                                            !compare_date(shop.current_period_end)
                                                                                ? format_date(shop.current_period_end) :
                                                                                <b className="badge badge-danger">{t('Expired')}</b>
                                                                            :
                                                                            shop.trial_end ?
                                                                                !compare_date(shop.trial_end)
                                                                                    ? format_date(shop.trial_end)
                                                                                    :
                                                                                    <b className="badge badge-danger">{t('Expired')}</b>
                                                                                :
                                                                                <b className="badge badge-danger">{t('Incomplet')}</b>

                                                                        }</td>
                                                                        <td>{shop?.step}</td>
                                                                        <td>

                                                                            {shop.statut == 2 ?
                                                                                <b className="badge badge-soft-success">{t('Available')}</b>
                                                                                :
                                                                                <b className="badge badge-soft-warning">{t('Unavailable')}</b>
                                                                            }

                                                                        </td>

                                                                        <td className="priority"
                                                                            style={{ textAlign: 'right' }}>
                                                                            <ul className="list-inline  mb-0">
                                                                                <li className="list-inline-item">
                                                                                    {shop.statut == 2
                                                                                        ? <span
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            onClick={event => toggleStatus(shop.shop_key, 2)}>
                                                                                                <i className="ri-close-line align-bottom me-2 text-danger"></i>
                                                                                             </span>
                                                                                        :

                                                                                        <span
                                                                                            className="edit-item-btn cursor-pointer"
                                                                                            onClick={event => toggleStatus(shop.shop_key, 1)}>
                                                                                                   <i className="ri-check-fill align-bottom me-2 text-success"></i>
                                                                                          </span>
                                                                                    }
                                                                                </li>
                                                                                <li className="list-inline-item">
                                                                                     <span
                                                                                         className="remove-item-btn cursor-pointer"
                                                                                         onClick={event => deleteModal(shop.id)}>
                                                                                         <i className="ri-delete-bin-fill align-bottom me-2 text-danger"></i>
                                                                                     </span>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        <tr>
                                                            <td colSpan="11">
                                                            <p className="text-center">{t('EmptyList')}</p>
                                                            </td>
                                                        </tr>
                                                }
                                            </>
                                    }

                                    </tbody>
                                </table>
                            </div>

                            <Paginate
                                postsPerPage={postsPerPage}
                                totalPosts={shops?.length}
                                paginate={paginate}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                current={currentPage}
                            />
                        </div>

                    </div>

                </div>

            </div>

        </AdminLayout>
    );
}

export default withTranslation()(Shops);
