import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {APPNAME, SERVER_URL} from "../../../../helpers/utils";
import axios from "axios";
import {toast} from "react-toastify";
import Cookies from 'js-cookie'
import {loadShopPages} from "../../../../store/Pages/action";
import {RegLoading} from "../../../../components";
import CustomRadioInput from "../../../../components/CustomInputs/CustomRadioInput";

const Options = ({t,loadShopPages,listShopPage : {list,isLoading},setStep}) => {

    document.title = APPNAME+" :: "+t('ConfigPage')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('ConfigPage'))

    const [loading,setLoading] = useState(false)
    const [GLoading,setGLoading] = useState(false)
    const [options, setOptions] = useState([]);

    useEffect(()=>{
        loadShopPages()
    },[])

    useEffect(() => {
        if (list.length > 0){
            const defaultChecked = list.filter((el) => el.selected_default)
            setOptions(defaultChecked)
        }
    }, [list]);



   const addToArray = (ev,lt) => {
       const verify  = options.filter(e => e.id == lt.id)

       if (verify.length){
           const opts = options.filter(object => {
               return object.page_key !== lt.page_key;
           });

           setOptions(opts)
       }else{
           setOptions(prevState => [...prevState,lt])
       }

 }




    const savePageSelected = async () =>{
        setLoading(true)
        const data = {
            pageOptions : JSON.stringify(options),
            page_price : 0,
            shop_key : Cookies.get('shop_key')
        }



        await axios.post(SERVER_URL+"pages/saved",data).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setGLoading(true)
                setTimeout(()=>{
                    setGLoading(false)
                    setStep(3)
                    Cookies.set('step',3)
                },2000)

            }else{
                    toast.error(t(response.data.message))
            }
        })



    }



    return (
        <div className="">
            <div className="row wow fadeInUp pt-20 pl-20 pb-30" data-wow-delay="0.1s">
                <div className="col-md-12 uppercase">
                    <h2 className="page-title">{t('ChoosePagesToadd')}  </h2>
                    <hr/>
                </div>
            </div>





            {
                isLoading || loading || GLoading

                    ?
                    <RegLoading />
                    :

                        <div className="row">

                     <div className="col-lg-12">
                         <div className="row">
                             {
                                 list?.length > 0 ?
                                     <>
                                         {
                                             list.map((lt, key) =>
                                                 <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6" key={key}>
                                                     <CustomRadioInput  onClick={event => addToArray(event,lt)} item={lt} t={t}  options={options}  />
                                                 </div>
                                             )
                                         }
                                     </>
                                     :
                                     <div className="text-center">
                                         liste vide
                                     </div>
                             }

                         </div>
                     </div>

                        <div className="offset-lg-8 col-md-4 m-t-5 bottom__button" style={{textAlign: "right"}}>
                            <button className="template-btn primary-bg-5 mr-30 wow fadeInUp"
                                    disabled={loading} onClick={event => savePageSelected()}
                                    data-wow-delay="0.4s">

                                {
                                    loading ?
                                        <><span className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"/>{t('Loading')}...</>
                                        :
                                        <span> {t('ChooseTemplate')}</span>
                                }

                            </button>
                        </div>
                    </div>

            }

        </div>
    );
}

const mapStateToProps = (state) => ({
    listShopPage : state.listshoppage,
});


export default   compose(connect(mapStateToProps, { loadShopPages })) (withTranslation()(Options));