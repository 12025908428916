import React,{useState,useEffect} from 'react';
import {CustomModal} from "../../../../components";
import {
    attachTokenToHeaders,
    changeCurrency,
    formatPhoneNumber,
    isEmail,
    numberWithCommas,
    SERVER_URL
} from "../../../../helpers/utils";
import Cookies from "js-cookie";


import CustomPhoneInput from "../../../../components/CustomInputs/CustomPhoneInput";
import {toast} from "react-toastify";
import axios from "axios";

const WithdrawMethod = ({open,setOpen,t,amount,auth,created}) => {

    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [selected, setSelected] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [addNewChoosed, setAddNewChoosed] = useState(0);
    const [password, setPassword] = useState("");
    const [payPalEmail, setPayPalEmail] = useState("");

    const [canGetMoney, setCanGetMoney] = useState("");

    useEffect(() => {
             if (localStorage.getItem("--COOK__RETURN--")) setCanGetMoney(localStorage.getItem("--COOK__RETURN--"))
    }, []);




    const handleChooseMethod = (e) =>{
        setSelected(e.target.value)
        setPhone(e.target.value)
    }



   const getPayed =  () =>{
            if (addNewChoosed == 1){
                if (phone == ""){
                    toast.error(t('InputRequired'))
                }else{
                    SendToServer(1,phone)
                }
            }
            else if (addNewChoosed == 2){
                if (!isEmail(payPalEmail)){
                    toast.error(t('InputRequired'))
                }else{
                    SendToServer(2,payPalEmail)
                }
            }
   }


       const SendToServer = async (methode,el) => {
        setLoading(true)
          const body = {
              user_id : auth?.me?.id,
              method : methode,
              phoneNumber : el,
              password : password,
              email : auth?.me?.email,
          }
           await axios.post(SERVER_URL+"/admin/withdraw/money",body,attachTokenToHeaders()).then((response)=>{
               setLoading(false)
               if (response.data.succeed){
                   setLoading(false)
                   toast.success(t(response.data.message))
                   Cookies.set('--COOK__RETURN--', +"admin__rest__", { expires: response.data.expires });
                   setOpen(false)
                   created()
               }else{
                   setLoading(false)
                   toast.error(t(response.data.message))
               }
           })
       }


    return (
        <>
            <CustomModal open={open} setOpen={setOpen} title={t('Withdraw') + " "+t('Payment')} >
                   <div className="row text-center">
                       <h1 style={{fontSize : "50px",marginTop : "20px",marginBottom : "20px"}}>{numberWithCommas(amount)} <small><small>Frcs</small></small></h1>
                       <p className="text-muted"><small>{t('AvailableAmountAndConfirmBefore')}</small></p>
                       <br/>


                       <div className="row">
                           <div className="col-lg-12">
                               <div className="withdraw_m_add">
                                   <i className="ri-add-fill"></i> <span>{t('Method')}</span>
                               </div>
                           </div>


                       </div>


                       <br/>


                      <div className="row">
                                   <div className="col-lg-12">
                                       <br/><br/>
                                   </div>
                                   <div className="col-lg-6">
                                       <label htmlFor='momo'>
                                           <span><input type="radio"  name="payment_method" id="momo" onClick={event => setAddNewChoosed(1)} /> {t('MoMo')}</span>
                                       </label>
                                   </div>
                                   <div className="col-lg-6">
                                       <label htmlFor='paypal'>
                                           <span><input type="radio"  name="payment_method" id="paypal" onClick={event => setAddNewChoosed(2)} /> PayPal</span>
                                       </label>
                                   </div>
                                   <br/>
                                   {
                                       addNewChoosed == 1  ?

                                           <div className="col-lg-12">
                                               <hr  style={{marginTop : "20px",marginBottom : "20px"}} />
                                               <CustomPhoneInput phone={phone} setPhone={setPhone}  setCountry={setCountry}/>
                                           </div>
                                           : null
                                   }


                                   {
                                       addNewChoosed == 2  ?

                                           <div className="col-lg-12">
                                               <hr  style={{marginTop : "20px",marginBottom : "20px"}} />
                                               <input type="email" className="form-control form-control-lg" value={payPalEmail} onChange={event => setPayPalEmail(event.target.value)} placeholder="PayPal email"/>
                                           </div>
                                           : null
                                   }

                               </div>


                       <div className="col-lg-12">
                           <br/>
                           <br/>
                             <div className="text-left cursor-pointer">
                                 {
                                     selected != "" || phone || isEmail(payPalEmail) ?
                                         <label htmlFor="confirm">
                                             <input type="checkbox" id="confirm" onClick={event =>   setConfirmed(event.target.checked)} /> <span>{t('ConfirmWithdraw')}</span>
                                         </label>
                                         : null
                                 }

                                 {
                                     confirmed ?
                                         <div className="mt-3">
                                             <input type="password" className="front" placeholder={t('YourPassword')} value={password} onChange={event => setPassword(event.target.value)}/>
                                         </div>
                                         :
                                         ""
                                 }

                                 <br/>

                                 {
                                     canGetMoney !== "" ?
                                         <div className="alert alert-info">
                                             {t('PaymentLinkIsAlwaysSend')}
                                         </div>
                                         :

                                         <button className="btn btn-primary btn-lg btn-block"
                                                 disabled={!confirmed || loading || !password}
                                                 onClick={event => getPayed()}>
                                             {
                                                 loading ?
                                                     <span className="spinner-border spinner-border-sm" role="status"
                                                           aria-hidden="true" />
                                                     :
                                                     <span>  <i
                                                         className="ri-money-dollar-box-fill"></i> {t('withdraw')}</span>
                                             }


                                         </button>
                                 }


                             </div>
                       </div>
                   </div>
            </CustomModal>
        </>

    );
}

export default WithdrawMethod;
