export const LINK_LANDING = '/'
export const LINK_LIST_SHOPS = '/shops-list'
export const LINK_CONTACT = '/contact-us'
export const LINK_PRICING = '/pricing'
export const LINK_DOCS = '/docs/how-it-works'
export const LINK_NEWS = '/news'
export const LINK_REGISTER_STORE = '/store/new'
export const LINK_TRIAL_STORE = '/trial/new'
export const LINK_LOGIN_STORE = '/store/login'
export const LINK_FINISHED_STORE = '/store/created'
export const LINK_DEFINE_PASSWORD = '/define/new/password/:token'
export const LINK_PASSWORD_UPDATE = '/update/password'

export const LINK_ABOUT_US = '/apropos'
export const LINK_LEGAL= '/legal-notice'
export const LINK_PRIVACY= '/privacy-policy'
export const LINK_CONDITIONS= '/terms-conditions'


// Store panel
export const LINK_STORE_DASHBOARD = '/store/dashboard'
export const LINK_STORE_STARTER = '/store/starter'
export const LINK_STORE_PAGES = '/store/pages'
export const LINK_STORE_CUSTOMERS = '/store/customers'
export const LINK_STORE_ORDERS = '/store/orders'
export const LINK_STORE_PAYMENT = '/store/payments'
export const LINK_STORE_WITHDRAW = '/store/withdraws'
export const LINK_STORE_SUPPORT = '/store/support'
export const LINK_STORE_ASSISTANCE = '/store/assistance'
export const LINK_STORE_CATEGORIES = '/store/categories'
export const LINK_STORE_SUBCATEGORIES = '/store/subcategories'
export const LINK_STORE_PRODUCTS = '/store/products'
export const LINK_STORE_COUPON = '/store/coupon'
export const LINK_STORE_PRODUCT_CREATE = '/store/product/create'

export const LINK_STORE_USERS = '/store/collaboraters'
export const LINK_STORE_PERMISSIONS = '/store/permissions'
export const LINK_STORE_PROFIL = '/store/profil'
export const LINK_STORE_SETTINGS = '/store/settings'
export const LINK_STORE_PACKAGES = '/store/packages'
export const LINK_STORE_INVOICE = '/store/invoice'
export const LINK_STORE_APPS = '/store/applications'


export const LINK_STORE_UPTURN = '/store/improvements'
export const LINK_STORE_MY_PLAN = '/store/subscription/plan'
export const LINK_STORE_CHANGE_PLAN = '/store/subscription/change'

export const LINK_STORE_NOTIFICATION = '/store/notifications'




// Admin panel
export const LINK_ADMIN_DASHBOARD = '/admin/dashboard'
export const LINK_ADMIN_PLUGINS = '/admin/plugins'
export const LINK_ADMIN_DEMO = '/admin/demo'
export const LINK_ADMIN_CATEGORIES = '/admin/categories'
export const LINK_ADMIN_BLOG = '/admin/blogs'
export const LINK_ADMIN_PAYMENTS = '/admin/payments'
export const LINK_ADMIN_PRICING = '/admin/pricing'
export const LINK_ADMIN_SHOPS = '/admin/shops'
export const LINK_ADMIN_SUPPORT = '/admin/supports'
export const LINK_ADMIN_ON_CLICK = '/admin/onclick/create'
export const LINK_ADMIN_PARAMS = '/admin/params'
export const LINK_ADMIN_USERS = '/admin/users'
export const LINK_ADMIN_TEMPLATES = '/admin/templates'
export const LINK_ADMIN_MODULES = '/admin/modules'
export const LINK_ADMIN_STORAGE = '/admin/storage'
export const LINK_ADMIN_CONTACT = '/admin/contact'
export const LINK_ADMIN_PAGES = '/admin/pages'
export const LINK_ADMIN_NOTIFICATION = '/admin/notifications'
export const LINK_ADMIN_COMMERCIAUX = '/admin/commercials'
export const LINK_ADMIN_PROMO = '/admin/promo'
export const LINK_ADMIN_EVENTS = '/admin/events'
export const LINK_ADMIN_PERMISSIONS = '/admin/permissions'



// PLUGIN DEFINITION
export const LINK_STORE_DELIVERER = '/store/apps/delivery'
export const LINK_STORE_LABEL = '/store/apps/labels'
export const LINK_STORE_FACEBOOK_MARKET_PLACE = '/store/apps/facebook/marketplace'
export const LINK_STORE_CHART = '/store/apps/charts'
export const LINK_STORE_WHATSAPP = '/store/apps/whatsapp'
export const LINK_STORE_MESSENGER = '/store/apps/messenger'
