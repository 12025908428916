import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LINK_CONDITIONS} from "../../../../routes/type";
import * as Yup from 'yup'
import {Field, Form, Formik} from "formik";
import { APPNAME, DOMAINE, isPhoneNumber, SERVER_URL } from '../../../../helpers/utils'
import {compose} from "redux";
import {connect} from "react-redux";
import {storeShop} from "../../../../store/Register/action";
import {toast} from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";
import {RegLoading} from "../../../../components";
import CustomPhoneInput from "../../../../components/CustomInputs/CustomPhoneInput";
import {loadHome} from "../../../../store/Home/action";
import logo from "../../../../assets/logo.png";


const Register = ({t,storeShop,register : {isLoading,error,shop_add,key},setStep,home,loadHome}) => {

    document.title = APPNAME+" :: "+t('RegisterShop')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('RegisterShop'))
    document.querySelector("meta[name='description']").setAttribute("content", "Enrégistrer ma boutique")

    useEffect(() => {
        localStorage.setItem('currency',"XOF")
    }, []);

    useEffect(()=>{
        loadHome()
    },[])


    const [name,setName] = useState("")
    const [exist,setExist] = useState(true)
    const [loading,setLoading] = useState(false)
    const [Gloading,setGLoading] = useState(false)
    const [existError,setExistError] = useState(false)
    const [existErrorMsg,setExistErrorMsg] = useState("")
    const [country,setCountry] = useState({})
    const [suggestions, setSuggestions] = useState([]);
    const [text, setText] = useState("Check");

    const buildDomaineName = (name) => {
        return name.toLowerCase()
                   .split(' ')
                   .join('')
                   .normalize("NFD")
                   .replace(/[\u0300-\u036f]/g, "")
                   .replace(/'/g, '')
                   .replace(/"/g, '')
                   .replace(/"/g, '')
                   .replace(/[()]/g, '')
    }

    const validationSchema = Yup.object().shape({
        lastname: Yup.string()
            .required(t('LastNameRequired')),
        firstname: Yup.string()
            .required(t('InputRequired')),
        email: Yup.string()
            .required(t('InputRequired')),
        phone: Yup.string()
            .required(t('InputRequired')),
        category: Yup.string()
            .required(t('InputRequired')),
        domaine: Yup.string()
            .required(t('InputRequired')).lowercase(t('DomaineLowercase')),
    });

    const  initialValues= {
            lastname: '',
            firstname: '',
            email: '',
            phone: "",
            category: "",
            shopName : name,
            domaine: name,
    }

    const handleSubmit = (values) =>{

        if (!isPhoneNumber(values.phone)){
            toast.error(t("PhoneRequired"))
        }else {
            const data = {
                lastname: values.lastname,
                firstname: values.firstname,
                email: values.email,
                phone: values.phone,
                category: values.category,
                shopName: values.domaine,
                domaine: buildDomaineName(values.domaine)
            }
            storeShop(data)
        }
    }

    useEffect(()=>{
        if (error) {
            toast.error(t(error))
        }
    },[error,t])

    useEffect(()=>{
        if (shop_add){
            setGLoading(true)
            setTimeout(()=>{
                setGLoading(false)
                setStep(2)
                Cookies.set('step',2)
                Cookies.set('shop_key',key)

            },2000)
        }
    },[shop_add])

    const checkExistShop = async (e) =>{
      e.preventDefault()
        setExistError(false)
        setLoading(true)
      const data = {
          domaine : buildDomaineName(name)
      }

    await axios.post(SERVER_URL+"check/store",data).then((response)=>{
        setLoading(false)
        console.log(response.data)
        if (response.data.succeed){
             setGLoading(true)
            setTimeout(()=>{
                setGLoading(false)
                setExist(false)
                setExistError(false)
            },2000)

         }
        else{
            if (response.data.step){
                Cookies.set('step',response.data.step)
                Cookies.set('shop_key',response.data.key)
                setGLoading(true)
                setTimeout(()=>{
                    setStep(parseInt(response.data.step))
                    setGLoading(false)
                    setExist(false)
                    setExistError(false)
                },2000)
            }else{
                setExistError(true)
                const domainSuggestions = suggestDomain(name);
                setSuggestions(domainSuggestions);
                setExistErrorMsg(response.data.message)
            }
         }
     })

    }


    const suggestDomain = (baseDomain) => {
        const suggestions = [];
        const prefixSuffix = ['Shop', 'Store', 'Market', 'Online'];

        for (let prefix of prefixSuffix) {
            const newDomain = `${prefix} ${baseDomain}`;
            suggestions.push(newDomain);
        }

        for (let suffix of prefixSuffix) {
            const newDomain = `${baseDomain} ${suffix}`;
            suggestions.push(newDomain);
        }


        return suggestions;
    };

    const selectAnother = (value) =>{
         setName(value);
          setText("Next")
    }




    return (
       <>
                                  {
                                      Gloading ?

                                         <div className="pt-50">
                                             <RegLoading />
                                         </div>
                                          :

                                          <div className="col-lg-12" >

                                              {
                                                  exist ?
                                                      <div className="form__content">
                                                          <div className={suggestions.length > 0 ? 'pb-30' : "pt-90 pb-30"}>
                                                              <img src={logo} alt="kiiako" style={{width: "150px"}}/>
                                                          </div>
                                                          <h3>{t('Checking')}</h3>
                                                          <br/>
                                                          <p>{t('CheckingMsg')}</p>
                                                          <br/>
                                                          {existError ?
                                                              <p className="text-danger">{t(existErrorMsg)}</p> : null}
                                                          <form className="row" onSubmit={event => checkExistShop(event)}>

                                                              {suggestions.length > 0 && (
                                                                  <div>
                                                                      <p>{t('Suggestions')}</p>
                                                                      <ul className="row row-cols-6">
                                                                          {suggestions.map((suggestion, index) => (
                                                                              <li className="badge badge-primary btn-sm m-1 col cursor-pointer" onClick={event => selectAnother(suggestion)} key={index}>{suggestion}</li>
                                                                          ))}
                                                                      </ul>
                                                                  </div>
                                                              )}

                                                              <div className="col-sm-9">
                                                                  <input type="text" className='front' value={name}
                                                                         autoFocus={true}
                                                                          placeholder="Ex : Afiwa Shop"
                                                                         onChange={event => setName(event.target.value)}
                                                                         required={true}/>
                                                              </div>
                                                              <div className="col-sm-3">
                                                                  <button type="submit"
                                                                          className="template-btn primary-bg-5"
                                                                          style={{display: "flex"}}>
                                                                      {
                                                                          loading ?
                                                                              <><span
                                                                                  className="spinner-border spinner-border-sm"
                                                                                  role="status"
                                                                                  aria-hidden="true"/>{t('Loading')}...</>
                                                                              :
                                                                              <span> {t(text)}</span>
                                                                      }
                                                                  </button>
                                                              </div>
                                                          </form>
                                                      </div>
                                                      :
                                                      <div >
                                                          <div className="sign-in-up-wrapper p-l-20 p-t-20 p-r-20">
                                                              <h2 className="page-title pl-3" style={{
                                                                  display: "flex",
                                                                  justifyContent: "space-between",

                                                              }}>
                                                                  {t('RegisterShop')}
                                                                  <span style={{
                                                                      display: "flex",
                                                                      flexDirection: "column"
                                                                  }}>
                                                              <b className="fs-14 ml-10 text-success">{name}</b>
                                                             <b className="fs-10 text-success mt-10">
                                                                 <i className="bx bx-link"
                                                                    style={{ marginRight: "5px" }}></i>
                                                                 <a target="_blank"
                                                                    href={`https://${buildDomaineName(name)}.${DOMAINE}`}>https://{buildDomaineName(name)}.{DOMAINE}</a>
                                                             </b>
                                                              </span>
                                                              </h2>
                                                              <p className="text-success pl-3">{t('YouCanAddShop')}</p>
                                                              <Formik
                                                                  initialValues={initialValues}
                                                                  onSubmit={handleSubmit}
                                                                  validationSchema={validationSchema}
                                                              >
                                                                  {({ errors, touched, values, setFieldValue }) => (
                                                                      <Form className="form-reg" id="scrollable-container">
                                                                          <div className="row">
                                                                              <div className="offset-lg-3 col-lg-6">
                                                                                  <div className="form-groups">
                                                                                      <h6>{t('Category')}</h6>
                                                                                      <hr />
                                                                                      <div className="row mb-4">
                                                                                          <div className="col-md-12">
                                                                                              <div
                                                                                                  className="field-group">
                                                                                                  <Field id="category"
                                                                                                         className="front nice-select"
                                                                                                         as="select"
                                                                                                         name="category"
                                                                                                         placeholder={t("Firstname")}
                                                                                                         style={{ borderRadius: "7px" }}>
                                                                                                      <option
                                                                                                          value="">--{t('Choose')}--
                                                                                                      </option>
                                                                                                      {
                                                                                                          home.categories?.length > 0 ?
                                                                                                              <>
                                                                                                                  {
                                                                                                                      home.categories.map((category, key) =>
                                                                                                                          <option
                                                                                                                              key={key}
                                                                                                                              value={category.id}>{category?.[localStorage.getItem('I18N_LANGUAGE')]}</option>
                                                                                                                      )
                                                                                                                  }
                                                                                                              </>

                                                                                                              :
                                                                                                              <option>{t('EmptyList')}...</option>
                                                                                                      }
                                                                                                  </Field>
                                                                                                  {errors.category && touched.category ? (
                                                                                                      <div
                                                                                                          className='text-danger'>{errors.category}</div>
                                                                                                  ) : null}
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>


                                                                                      <h6 className="mt-50">{t('ShopResponsable')}</h6>
                                                                                      <hr />
                                                                                      <div className="row mb-4">
                                                                                          <div className="col-md-12">
                                                                                              <div className="">
                                                                                                  <Field id="lastname"
                                                                                                         className='front'
                                                                                                         name="lastname"
                                                                                                         placeholder={t("Lastname")} />
                                                                                                  {errors.lastname && touched.lastname ? (
                                                                                                      <div
                                                                                                          className='text-danger'>{errors.lastname}</div>
                                                                                                  ) : null}
                                                                                              </div>
                                                                                          </div>
                                                                                          <br />
                                                                                          <div className="col-md-12">
                                                                                              <div className="mt-20">
                                                                                                  <Field id="firstname"
                                                                                                         className='front'
                                                                                                         name="firstname"
                                                                                                         placeholder={t("Firstname")} />
                                                                                                  {errors.firstname && touched.firstname ? (
                                                                                                      <div
                                                                                                          className='text-danger'>{errors.firstname}</div>
                                                                                                  ) : null}
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>

                                                                                      <h6 className="mt-50">{t('ShopInformations')}</h6>
                                                                                      <hr />
                                                                                      <div className="row mb-3">
                                                                                          <div className="col-md-12">
                                                                                              <div className="">
                                                                                                  <Field type="email"
                                                                                                         id="email"
                                                                                                         name="email"
                                                                                                         className='front'
                                                                                                         placeholder="Email" />
                                                                                                  {errors.email && touched.email ? (
                                                                                                      <div
                                                                                                          className='text-danger'>{errors.email}</div>
                                                                                                  ) : null}
                                                                                              </div>
                                                                                          </div>
                                                                                          <br />
                                                                                          <div className="col-md-12">
                                                                                              <div className="mt-20">
                                                                                                  <CustomPhoneInput
                                                                                                      phone={values.phone}
                                                                                                      name="phone"
                                                                                                      setPhone={event => setFieldValue("phone", event)}
                                                                                                      setCountry={setCountry} />

                                                                                                  {errors.phone && touched.phone ? (
                                                                                                      <div
                                                                                                          className='text-danger'>{errors.phone}</div>
                                                                                                  ) : null}
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>

                                                                                      <div className="field-group"
                                                                                           style={{ display: "none" }}>
                                                                                          <Field type="text"
                                                                                                 id="domaine"
                                                                                                 name="domaine"
                                                                                                 className='front'
                                                                                                 placeholder={t('ShopName')}
                                                                                                 readOnly={true} />
                                                                                          {errors.domaine && touched.domaine ? (
                                                                                              <div
                                                                                                  className='text-danger'>{errors.domaine}</div>
                                                                                          ) : null}
                                                                                      </div>


                                                                                      <p style={{
                                                                                          fontSize: "13px",
                                                                                          paddingLeft: "10",
                                                                                          marginTop: "20px"
                                                                                      }}>
                                                                                          {t('MessageBeforeSubmitShop')}
                                                                                          <Link
                                                                                              to={LINK_CONDITIONS}> {t('Termsndcondition')}</Link>
                                                                                      </p>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                          <div className="row">
                                                                              <div
                                                                                  className="m-b-20 offset-lg-3 col-lg-6">
                                                                                  <div
                                                                                      className="mt-10 d-flex mr-30 justify-content-between">
                                                                                      <button
                                                                                          className="template-btn bg-soft-grey-color"
                                                                                          type="button"
                                                                                          onClick={event => setExist(true)}>
                                                                                          {t('Previous')}
                                                                                      </button>
                                                                                      <button type="submit"
                                                                                              className="template-btn primary-bg-5"
                                                                                              disabled={isLoading}>
                                                                                          {
                                                                                              isLoading ?
                                                                                                  <><span
                                                                                                      className="spinner-border spinner-border-sm"
                                                                                                      role="status"
                                                                                                      aria-hidden="true" />{t('Loading')}...</>
                                                                                                  :
                                                                                                  <span> {t('GetStart')}</span>
                                                                                          }
                                                                                      </button>
                                                                                  </div>
                                                                              </div>
                                                                          </div>

                                                                      </Form>
                                                                  )}
                                                              </Formik>
                                                          </div>
                                                      </div>
                                              }
                                          </div>
                                  }

       </>
    );
}

const mapStateToProps = (state) => ({
    register: state.registershop,
    home: state.home
});


export default compose(connect(mapStateToProps, { storeShop, loadHome }))(withTranslation()(Register));
