import {
  LINK_ADMIN_DASHBOARD,
  LINK_CONTACT,
  LINK_DEFINE_PASSWORD,
  LINK_DOCS,
  LINK_FINISHED_STORE,
  LINK_LANDING,
  LINK_LIST_SHOPS,
  LINK_LOGIN_STORE,
  LINK_NEWS,
  LINK_PASSWORD_UPDATE,
  LINK_PRICING,
  LINK_REGISTER_STORE,
  LINK_STORE_CATEGORIES,
  LINK_STORE_COUPON,
  LINK_STORE_CUSTOMERS,
  LINK_STORE_DASHBOARD,
  LINK_STORE_ORDERS,
  LINK_STORE_PAGES,
  LINK_STORE_PAYMENT,
  LINK_STORE_PRODUCT_CREATE,
  LINK_STORE_PRODUCTS,
  LINK_STORE_PROFIL,
  LINK_STORE_SETTINGS,
  LINK_STORE_SUBCATEGORIES,
  LINK_STORE_SUPPORT,
  LINK_STORE_USERS,
  LINK_STORE_WITHDRAW,
  LINK_CONDITIONS,
  LINK_ABOUT_US,
  LINK_TRIAL_STORE,
  LINK_STORE_UPTURN,
  LINK_STORE_DELIVERER,
  LINK_STORE_ASSISTANCE,
  LINK_STORE_PERMISSIONS,
  LINK_STORE_PACKAGES,
  LINK_ADMIN_PLUGINS,
  LINK_ADMIN_SHOPS,
  LINK_ADMIN_PAYMENTS,
  LINK_ADMIN_SUPPORT,
  LINK_ADMIN_CATEGORIES,
  LINK_ADMIN_PARAMS,
  LINK_ADMIN_MODULES,
  LINK_ADMIN_STORAGE,
  LINK_ADMIN_CONTACT,
  LINK_ADMIN_PAGES,
  LINK_ADMIN_PRICING,
  LINK_ADMIN_BLOG,
  LINK_STORE_APPS,
  LINK_STORE_STARTER,
  LINK_ADMIN_USERS,
  LINK_ADMIN_TEMPLATES,
  LINK_ADMIN_NOTIFICATION,
  LINK_STORE_NOTIFICATION,
  LINK_STORE_MY_PLAN,
  LINK_STORE_CHANGE_PLAN,
  LINK_STORE_LABEL,
  LINK_STORE_CHART,
  LINK_STORE_WHATSAPP,
  LINK_STORE_MESSENGER,
  LINK_LEGAL,
  LINK_PRIVACY,
  LINK_ADMIN_DEMO,
  LINK_ADMIN_COMMERCIAUX,
  LINK_ADMIN_PERMISSIONS, LINK_STORE_FACEBOOK_MARKET_PLACE, LINK_ADMIN_PROMO,
} from './type'
import {
  Contact,
  Landing,
  Login,
  NotFound,
  Index,
  ShopsList,
  Finished,
  PasswordAdd,
  CheckEMail,
  UpdatePassword,
  Tarification,
  Docs,
  News,
  RegisterSponsor,
  Signup,
  ConditionsOfUsed,
  AboutUs,
  NewsDetail,
  TrialRegister,
  Created,
  Invitation,
  Policies,
  Legals
} from "../pages/Front";
import {
  Categories,
  Customers,
  Dashboard,
  MySupport,
  OrderDetail,
  Orders,
  Pages,
  Payment,
  Profil,
  SubCategories,
  StoreDashboard,
  Products,
  ProductCreate,
  ProductDetail,
  ProductEdit,
  Coupon,
  CustomerDetail,
  SupportDetail,
  Users,
  InvoiceDetail,
  Settings,
  Withdraw,
  Upturn,
  Deliverer,
  Assistance,
  Permissions,
  Package,
  Notification,
  AdminNotification,
  Plugins,
  Shops,
  AdminPayment,
  AdminSupport,
  AdminCategories,
  Params,
  Modules,
  Storages,
  AdminContact,
  AdminPage,
  AdminPricing,
  AdminBlog,
  Applications,
  Discussions,
  Starter,
  AdminUsers,
  AdminTemplate,
  List,
  AdminBuilder,
  PageBuilder,
  ChangePlan,
  MyPlan,
  SlackIntegration,
  Labels,
  Charts,
  WhatsappConf,
  MessengerConf,
  Demo,
  Received,
  Commercial, AdminPermissions, ConnectFacebook, Promo,
} from '../pages/Admin'
import Preview from "../themes/Preview";
import ThemePreview from "../pages/Preview/ThemePreview";




const authProtectedRoutes = [
  { path: LINK_STORE_STARTER, isLogged : true, component : Starter},
  { path: LINK_STORE_DASHBOARD, isLogged : true, component : StoreDashboard},
  { path: LINK_STORE_CUSTOMERS, isLogged : true, component : Customers},
  { path: LINK_STORE_ORDERS, isLogged : true, component : Orders},
  { path: LINK_STORE_PAGES, isLogged : true, component : Pages},
  { path: LINK_STORE_PAYMENT, isLogged : true, component : Payment},
  { path: LINK_STORE_SUPPORT, isLogged : true, component : MySupport},
  { path: LINK_STORE_ASSISTANCE, isLogged : true, component : Assistance},
  { path: LINK_STORE_PROFIL, isLogged : true, component : Profil},
  { path: LINK_STORE_SUBCATEGORIES, isLogged : true, component : SubCategories},
  { path: LINK_STORE_CATEGORIES, isLogged : true, component : Categories},
  { path: LINK_STORE_PRODUCTS, isLogged : true, component : Products},
  { path: LINK_STORE_PRODUCT_CREATE, isLogged : true, component : ProductCreate},
  { path: LINK_STORE_COUPON, isLogged : true, component : Coupon},
  { path: LINK_STORE_USERS, isLogged : true, component : Users},
  { path: LINK_STORE_SETTINGS, isLogged : true, component : Settings},
  { path: LINK_STORE_WITHDRAW, isLogged : true, component : Withdraw},
  { path: "/store/invoice/:order_id", isLogged : true, component : InvoiceDetail},
  { path: "/store/customer/detail/:hash_key", isLogged : true, component : CustomerDetail},
  { path: "/store/support/detail/:hash_key", isLogged : true, component : SupportDetail},
  { path: "/store/support/:hash_key", isLogged : true, component : Discussions},
  { path: "/admin/support/:hash_key", isLogged : true, component : Discussions},
  { path: "/store/order/:hash_key", isLogged : true, component : OrderDetail},
  { path: "/store/product/detail/:hash_key", isLogged : true, component : ProductDetail},
  { path: "/store/product/edit/:hash_key", isLogged : true, component : ProductEdit},

  { path: LINK_STORE_UPTURN, isLogged : true, component : Upturn},
  { path: LINK_STORE_MY_PLAN, isLogged : true, component : MyPlan},
  { path: LINK_STORE_CHANGE_PLAN, isLogged : true, component : ChangePlan},


  { path: LINK_STORE_PERMISSIONS, isLogged : true, component : Permissions},
  { path: LINK_STORE_PACKAGES, isLogged : true, component : Package},
  { path: LINK_STORE_APPS, isLogged : true, component : Applications},
  { path: LINK_STORE_NOTIFICATION, isLogged : true, component : Notification},

  { path: "/store/page/editor/:hash_key", isLogged : true, component : PageBuilder},
  { path: "/page/preview/:hash_key", isLogged : true, component : Preview},


  { path: "/store/slack-integration", isLogged : false, component : SlackIntegration},


    // Apps

  { path: LINK_STORE_DELIVERER, isLogged : true, component : Deliverer},
  { path: LINK_STORE_LABEL, isLogged : true, component : Labels},
  { path: LINK_STORE_CHART, isLogged : true, component : Charts},
  { path: LINK_STORE_WHATSAPP, isLogged : true, component : WhatsappConf},
  { path: LINK_STORE_MESSENGER, isLogged : true, component : MessengerConf},
  { path: LINK_STORE_FACEBOOK_MARKET_PLACE, isLogged : true, component : ConnectFacebook},




    // Admin
  { path: LINK_ADMIN_DASHBOARD, isLogged : true, component : Dashboard},
  { path: LINK_ADMIN_PLUGINS, isLogged : true, component : Plugins},
  { path: LINK_ADMIN_SHOPS, isLogged : true, component : Shops},
  { path: LINK_ADMIN_PAYMENTS, isLogged : true, component : AdminPayment},
  { path: LINK_ADMIN_SUPPORT, isLogged : true, component : AdminSupport},
  { path: LINK_ADMIN_DEMO, isLogged : true, component : Demo},
  { path: LINK_ADMIN_CATEGORIES, isLogged : true, component : AdminCategories},
  { path: LINK_ADMIN_PARAMS, isLogged : true, component : Params},
  { path: LINK_ADMIN_MODULES, isLogged : true, component : Modules},
  { path: LINK_ADMIN_STORAGE, isLogged : true, component : Storages},
  { path: LINK_ADMIN_CONTACT, isLogged : true, component : AdminContact},
  { path: LINK_ADMIN_PAGES, isLogged : true, component : AdminPage},
  { path: LINK_ADMIN_PRICING, isLogged : true, component : AdminPricing},
  { path: LINK_ADMIN_BLOG, isLogged : true, component : AdminBlog},
  { path: LINK_ADMIN_USERS, isLogged : true, component : AdminUsers},
  { path: LINK_ADMIN_TEMPLATES, isLogged : true, component : AdminTemplate},
  { path: LINK_ADMIN_NOTIFICATION, isLogged : true, component : AdminNotification},
  { path: "/admin/template/:hash_key", isLogged : true, component : List},
  { path: "/admin/template/builder/:hash_key", isLogged : true, component : AdminBuilder},

  { path: "/admin/template/preview/:hash_key", isLogged : true, component : Preview},


  { path: LINK_ADMIN_COMMERCIAUX, isLogged : true, component : Commercial},
  { path: LINK_ADMIN_PERMISSIONS, isLogged : true, component : AdminPermissions},

  { path: LINK_ADMIN_PROMO, isLogged : true, component : Promo},









]

const publicRoutes = [
  { path: LINK_LANDING, exact: true, component : Landing },

  { path: LINK_LOGIN_STORE,  component : Login },
  { path: LINK_LIST_SHOPS,  component : ShopsList },

  //Registration
  { path: LINK_REGISTER_STORE,  component : Index },
  { path: LINK_FINISHED_STORE,  component : Finished },
  { path: LINK_DEFINE_PASSWORD,  component :  PasswordAdd },
  { path: LINK_PASSWORD_UPDATE,  component :  CheckEMail },
  { path: "/update/password/:token",  component :  UpdatePassword },


    // Trial register

  { path: LINK_TRIAL_STORE,  component : TrialRegister },


  { path: "/delivery/created/:token", component : Created},
  { path: "/delivery/invitation/:token", component : Invitation},


  { path: "/store/register/sponsorship/:shop_key", component : RegisterSponsor},
  { path: "/create/account/:token",component : Signup},



  { path: "/preview/theme",  component :  ThemePreview },


  // Vitrine
  { path: LINK_CONTACT,  component : Contact },
  { path: LINK_PRICING,  component :  Tarification },
  { path: LINK_DOCS,  component : Docs },
  { path: LINK_NEWS,  component : News },
  { path: "/news/:slug",  component : NewsDetail },

  { path: LINK_CONDITIONS,  component : ConditionsOfUsed },
  { path: LINK_PRIVACY,  component : Policies },
  { path: LINK_LEGAL,  component : Legals },
  // { path: LINK_ABOUT_US,  component : AboutUs },

  { path: "/withdraw-payment/payment/:shop_id/:amount/:hash_key", isLogged : false, component : Received},


  // 404
  { path: "*",  component : NotFound },
  { path: "/error/notfound",  component : NotFound },
]

const routlist = authProtectedRoutes.concat(publicRoutes)


export { routlist }
