import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import logo from '../../assets/logo.png'
import {Link} from "react-router-dom";
import {
    LINK_ADMIN_DASHBOARD,
    LINK_CONTACT,
    LINK_DOCS,
    LINK_LANDING,
    LINK_LIST_SHOPS,
    LINK_LOGIN_STORE, LINK_NEWS, LINK_PRICING, LINK_REGISTER_STORE,
    LINK_STORE_DASHBOARD, LINK_TRIAL_STORE,
} from '../../routes/type'
import {ActiveMenu, CustomChangeLanguage} from "../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";

const HomeHeader = ({t,auth})  => {
    useEffect(()=>{

    },[])

    const [sidebar, setSidebar] = useState(false)

    const toggleSidebar = () => {
       setSidebar(!sidebar)
    }

    return (
        <header className="template-header navbar-left absolute-header nav-primary-5 submenu-primary-5 sticky-header">
            <div className="container-fluid fluid-gap-70">
                <div className="header-inner">
                    <div className="header-left">
                        <div className="brand-logo">
                            <Link to={LINK_LANDING} >
                                <img src={logo} style={{width : "159px",paddingTop : "-10px"}} alt="logo"/>

                            </Link>

                        </div>
                        <nav className="nav-menu d-none d-xl-block">
                            <ul>
                                <li className={ActiveMenu(LINK_LANDING)}>
                                    <Link to={LINK_LANDING}>{t('Home')}</Link>
                                </li>

                                <li className={ActiveMenu(LINK_LIST_SHOPS)}>
                                    <Link to={LINK_LIST_SHOPS}>{t('Shops')}</Link>
                                </li>

                                <li className={ActiveMenu(LINK_PRICING)}>
                                    <Link  to={LINK_PRICING}>{t('Pricing')}</Link>
                                </li>

                                {/*<li className={ActiveMenu(LINK_DOCS)}>*/}
                                {/*    <Link  to={LINK_DOCS}>Docs</Link>*/}
                                {/*</li>*/}

                                <li className={ActiveMenu(LINK_NEWS)}>
                                    <Link  to={LINK_NEWS}>{t('News')}</Link>
                                </li>

                                <li className={ActiveMenu(LINK_CONTACT)}>
                                    <Link  to={LINK_CONTACT}>{t('Contact')}</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <ul className="header-extra">
                            {/*{*/}
                            {/*    !auth.isAuthenticated*/}
                            {/*        ?*/}
                            {/*<li className="d-none d-xl-block">*/}
                            {/*    <Link to={LINK_REGISTER_STORE} className="btn btn-info uppercase"*/}
                            {/*          data-wow-delay="0.4s">{t('RegisterShop')}*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*        :*/}
                            {/*        ""*/}
                            {/*}*/}

                            <li className="d-none d-sm-block">
                                <button  className={`user-login ${localStorage.getItem('I18N_LANGUAGE') === "fr" && "selected_lng" }`} onClick={event => CustomChangeLanguage('fr')} style={{border : "none",background : "none",marginRight : "10px"}}>FR</button>
                                <button  className={`user-login ${localStorage.getItem('I18N_LANGUAGE') === "en" && "selected_lng"}`} onClick={event => CustomChangeLanguage('en')} style={{border : "none",background : "none",marginRight : "10px"}}>EN</button>
                            </li>


                            {
                                auth.isAuthenticated
                                    ?
                                    <li  className={`d-none d-sm-block ${ActiveMenu(LINK_LIST_SHOPS)}`}>
                                        {
                                            auth.me?.role_id === 2
                                                ?
                                                <Link to={LINK_STORE_DASHBOARD} className="user-login">
                                                    <i className="far fa-user"></i>  {auth.me?.lastname?.substring(0,1)}. {auth.me?.firstname}
                                                </Link>

                                                :""
                                        }

                                        {
                                            auth.me?.role_id === 3
                                                ?
                                                <Link to={LINK_ADMIN_DASHBOARD} className="user-login">
                                                    <i className="far fa-user"></i>  {auth.me?.lastname?.substring(0,1)}. {auth.me?.firstname}
                                                </Link>

                                                :""
                                        }

                                    </li>
                                    :

                                    <li  className={`d-none d-sm-block ${ActiveMenu(LINK_LIST_SHOPS)}`}>
                                        <Link to={LINK_LOGIN_STORE} className="user-login">
                                            <i className="far fa-lock-open-alt"></i>  <span style={{textTransform : "uppercase"}}>{t('LoginShop')}</span>
                                        </Link>
                                    </li>
                            }

                            <li className="d-xl-none">
                                <a href="#" className="navbar-toggler" onClick={event => toggleSidebar()}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={`mobile-slide-panel ${sidebar ? "panel-on" : ""}`}>
                <div className="panel-overlay"></div>
                <div className="panel-inner">
                    <div className="mobile-logo">
                        <Link to={LINK_LANDING}>
                            <img src={logo} alt="Landio"/>
                        </Link>
                    </div>
                    <nav className="mobile-menu">
                        <ul>
                            <li className={ActiveMenu(LINK_LANDING)}>
                                <Link to={LINK_LANDING}>{t('Home')}</Link>
                            </li>

                            <li className={ActiveMenu(LINK_LIST_SHOPS)}>
                                <Link to={LINK_LIST_SHOPS}>{t('Shops')}</Link>
                            </li>

                            <li className={ActiveMenu(LINK_PRICING)}>
                                <Link  to={LINK_PRICING}>{t('Pricing')}</Link>
                            </li>

                            {/*<li className={ActiveMenu(LINK_DOCS)}>
                                <Link  to={LINK_DOCS}>Docs</Link>
                            </li>*/}

                            <li className={ActiveMenu(LINK_NEWS)}>
                                <Link  to={LINK_NEWS}>{t('News')}</Link>
                            </li>
                            <li className={ActiveMenu(LINK_CONTACT)}>
                                <Link  to={LINK_CONTACT}>{t('Contact')}</Link>
                            </li>
                            <li  className={`${ActiveMenu(LINK_LIST_SHOPS)}`}>
                                <Link to={LINK_LOGIN_STORE} className="user-login">
                                    {t('LoginShop')}
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <a href="#" className="panel-close" onClick={event => toggleSidebar()}>
                        <i className="fal fa-times"></i>
                    </a>
                </div>
            </div>

            <div className="off-canvas-wrapper">
                <div className="canvas-overlay"></div>
                <div className="canvas-inner">
                    <div className="canvas-logo">
                        <img src={logo} alt="Canvas Logo"/>
                    </div>
                    <div className="about-us">
                        <h5 className="canvas-widget-title">About Us</h5>
                        <p>
                            Sed perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium
                            totam rem aperams eaque ipsa quae abillo inventore veritatis
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="canvas-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="far fa-map-marker-alt"></i>
                                354 Oakridge, Camden NJ 08102 - USA
                            </li>
                            <li>
                                <i className="far fa-envelope-open"></i>
                                <a href="https://demo.webtend.net/cdn-cgi/l/email-protection#c7b4b2b7b7a8b5b387aba6a9a3aea8e9a4a8aa"><span
                                    className="__cf_email__"
                                    data-cfemail="5625232626392422163a3738323f397835393b">[email&#160;protected]</span></a>
                                <a href="www.landio.html" target="_blank">www.landio.net</a>
                            </li>
                            <li>
                                <i className="far fa-phone"></i>
                                <span><a href="tel:+01234567899">+012 (345) 678 99</a></span>
                                <span><a href="tel:+8563214">+8563214</a></span>
                            </li>
                        </ul>
                    </div>
                    <a href="#" className="canvas-close">
                        <i className="fal fa-times"></i>
                    </a>
                </div>
            </div>
        </header>
    );
}

const mapStateToProps = (state) => ({
    auth: state.loginShop,
});

export default   compose(connect(mapStateToProps)) (withTranslation()(HomeHeader));
