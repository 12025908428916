import React from 'react';
import noticeIcon from './hero/notice-icon.png'
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import { LINK_REGISTER_STORE, LINK_TRIAL_STORE } from '../../../../routes/type'
import pc from './hero/pc_all.png'
import orders from './hero/pc_ecran2.png'

const Hero = ({t}) => {
    return (
        <section className="hero-area-v7" >
            <div className="container-fluid">
                <div className="hero-content" >
                    <h3 className="hero-title wow fadeInLeft" data-wow-delay="0.8s">{t('LandingHero.big_title')}</h3>
                    <p className="wow fadeInUp" data-wow-delay="0.3s">
                        {t('LandingHero.subtitle')}
                    </p>

                    <Link to={LINK_REGISTER_STORE} className="template-btn primary-bg-5 wow fadeInUp">
                        <span style={{textTransform : "uppercase"}}>{t('RegisterShop')}</span>
                    </Link>

                    <span className="notice wow fadeInUp" data-wow-delay="0.5s">
                    <img src={noticeIcon} alt="Image"/> {t('Buildforbusiness')}
                </span>
                </div>
                <div className="hero-img d-none d-lg-block wow fadeInUp" data-wow-delay="0.2s">
                    <img src={pc} alt="Image" className="incrustrer"/>
                </div>
            </div>
        </section>
);
}

export default withTranslation()(Hero)
