import React, {useEffect, useState} from 'react';
import AdminLayout from "../../../../layouts/admin/AdminLayout";
import {
    APPNAME,
    attachTokenToHeaders,
    compareDateWithToday,
    numberWithCommas,
    SERVER_URL
} from "../../../../helpers/utils";
import {withTranslation} from "react-i18next";
import Paginate from "../../../../components/Paginate/Paginate";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import Select from "react-select";
import axios from "axios";
import {toast} from "react-toastify";

const Promo = ({t}) => {

    document.title = APPNAME+" :: "+t('Promo')


    const [loading,setLoading] = useState(false)
    const [promo,setPromo] = useState(null)


    useEffect(() => {
        loadPromo()
    }, []);


    const loadPromo = async () => {
        setLoading(true)
        await axios.get(SERVER_URL+"admin/promo",attachTokenToHeaders()).then((response)=>{
            setLoading(false)
            if (response.data.succeed){
                setPromo(response.data.promo)
            }
        })
    }


    const initialValues = {
        started_at: promo?.started_at || "",
        ended_at: promo?.ended_at || "",
    };

    const validationSchema = Yup.object().shape({
        started_at : Yup.string().required(t('InputRequired')),
        ended_at : Yup.string().required(t('InputRequired')),
    });


    const handleSubmit =  async (values) => {
        setLoading(true)
            await axios.post(SERVER_URL+"admin/promo/add",values,attachTokenToHeaders()).then((response)=>{
                setLoading(false)
                if (response.data.succeed){
                    toast.success(t(response.data.message))
                    loadPromo()
                }else{
                    toast.error(t(response.data.message))
                }
            })

    }



    return (
        <AdminLayout>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{t('Promo')}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">{APPNAME}</li>
                                <li className="breadcrumb-item active">{t('Promo')}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="offset-lg-3 col-lg-6">
                    <div className="card" id="tasksList">
                        <div className="card-header border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1"> {t('Config')} {t('Promo')} {promo ?
                                    <small className={!compareDateWithToday(promo?.ended_at) ? "badge badge-danger" : "badge badge-success"}>
                                        {compareDateWithToday(promo?.ended_at) ? <small>{t('Available')}</small> : <small>{t('Unavailable')}</small>}
                                    </small>
                                   : ""
                                }
                                </h5>

                            </div>
                        </div>

                        <div className="card-body">
                            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                                {({ errors, touched,values }) =>
                                    <Form>
                                        <div className="row">

                                            <div className="col-lg-6">
                                                <label htmlFor="started_at" className="form-label">{t('Start')} <b
                                                    className="text-danger">*</b></label>
                                                <Field className="front" type={"date"} id="started_at" name="started_at" />
                                                {errors.started_at && touched.started_at ? (
                                                    <div className="text-danger">{errors.started_at}</div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-6">
                                                <label htmlFor="ended_at" className="form-label">{t('End')} <b
                                                    className="text-danger">*</b></label>
                                                <Field className="front" type={"date"} id="ended_at" name="ended_at" />
                                                {errors.ended_at && touched.ended_at ? (
                                                    <div className="text-danger">{errors.ended_at}</div>
                                                ) : null}
                                            </div>



                                        </div>



                                        <div className="row">
                                            <div className="col-lg-8 pt-3">
                                                <small><b className="text-danger">*</b> {t('InputRequired')}</small>
                                            </div>
                                            <div className="col-lg-4 pt-3">
                                                <button className="btn btn-success btn-block" type="submit" disabled={loading}>
                                                    {
                                                        loading ?
                                                            <span className="spinner-border spinner-border-sm" role="status"
                                                                  aria-hidden="true" />
                                                            :
                                                            <span> {t('Submit')}</span>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                }

                            </Formik>



                        </div>

                    </div>

                </div>

            </div>


        </AdminLayout>
    )
}
export default withTranslation()(Promo);