import React, {useEffect, useState} from 'react';
import {APPNAME, compareDateWithToday, numberWithCommas, SERVER_IMG_URL, SERVER_URL} from "../../../../helpers/utils";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {loadShopPricing} from "../../../../store/Pricing/action";
import Cookies from "js-cookie";
import axios from "axios";
import {toast} from "react-toastify";
import {RegLoading} from "../../../../components";
import PricingModal from "./PricingModal";


const Pricing = ({t,list_pricing : {isLoading,error,pricing,promo},loadShopPricing,setStep}) => {

    document.title = APPNAME+" :: "+t('Pricing')
    document.querySelector("meta[name='title']").setAttribute("content", APPNAME+" :: "+t('Pricing'))
   

    const [all_price,setAllPrice] = useState(0)
    const [loading,setLoading] = useState(false)
    const [GLoading,setGLoading] = useState(false)
    const [open,setOpen] = useState(false)
    const [data,setData] = useState(null)

    useEffect(()=>{
        loadShopPricing()
    },[])

    const choosePlan = async (plan) =>{
        window.scrollTo(0,0)
        setLoading(true)
        const data = {
            plan : plan,
            shop_key : Cookies.get('shop_key'),
        }
        await axios.post(SERVER_URL+"pricing/choose",data).then((response)=>{
            setLoading(false)
            setOpen(false)

            if (response.data.succeed){
                setGLoading(true)
                setTimeout(()=>{
                    setGLoading(false)
                    setStep(5)
                    Cookies.set('step',5)
                    const price = parseInt(all_price) + parseInt(plan.price)
                    Cookies.set('total_price',price)
                    setAllPrice(price)
                },2000)

            }else{
                toast.error(t(response.data.message))
            }
        })

    }

    const selectPlan= (plan) => {
        setOpen(true)
        setData(plan)
    }

    useEffect(()=>{
       if (promo && compareDateWithToday(promo?.ended_at)){
           setTimeout( ()=>{
               const data = {
                   "id": 2,
                   "name": "Starter",
                   "price": "5000",
                   "color": "#cccccc",
                   "year_reduce": 25,
                   "pricing_key": "924519",
                   "statut": 1,
                   "created_at": "2023-01-15T11:55:34.000Z",
                   "updated_at": "2024-03-10T18:41:04.000Z",
                   "theme_customizable": 1,
                   "multi_langue": 1,
                   "domaine_name": "domaine",
                   "folder_size": "2",
                   "sponsor_shipping": 0,
                   "account_number": "3",
                   "mobile_access": 0,
                   "payment_method": "PayPal & Mobile money",
                   "support_tech": 1
               }
               choosePlan(data)
           },1000)
       }
    },[promo])



    return (
        <div className="">
            <div className="row wow fadeInUp mt-20"  data-wow-delay="0.1s">
                <div className="col-md-10">
                    <h2 className="page-title">{t('Pricing')}  </h2>
                </div>
            </div>
            <hr/>


            {
                promo && compareDateWithToday(promo?.ended_at) ?
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <RegLoading/>
                            </div>
                        </div>
                    </div>
                    :

                    <div className="p-5">
                        {
                            isLoading || loading || GLoading

                                ?
                                <RegLoading/>
                                :

                                <div className="row">
                                    <div className=" col-md-12">
                                        <div className="row">
                                            {
                                                pricing.length > 0 ?
                                                    <>
                                                        {
                                                            pricing.slice(1).map((price, key) =>

                                                                <div key={key}
                                                                     className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                                                                     data-wow-delay="0.3s"
                                                                     style={{
                                                                         visibility: "visible",
                                                                         animationDelay: "0.3s",
                                                                         animationName: "fadeInUp"
                                                                     }}>
                                                                    <div className="pricing-table m-t-30">

                                                                        <div className="plan-cost">
                                                                            <span className="currency">XOF</span>
                                                                            <span
                                                                                className="price">{numberWithCommas(price.price)}</span>
                                                                            <span
                                                                                className="plan-type">/ {t('Month')}</span>
                                                                        </div>

                                                                        <button className="template-btn primary-bg-5"
                                                                                onClick={event => selectPlan(price)}>{t('GetStart')}
                                                                            <i className="fas fa-arrow-right"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        liste vide
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                        }

                    </div>
            }

            <PricingModal
                title={t("Pricing")}
                open={open}
                setOpen={setOpen}
                data={data}
                ChoosePricing={choosePlan}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    list_pricing: state.list_pricing
});


export default compose(connect(mapStateToProps, {loadShopPricing}))(withTranslation()(Pricing));